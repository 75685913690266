
































































import SearchBuilder from "@/builder/SearchBuilder";
import FormModals from "@/components/Branch/FormModals.vue";
import { APagination } from "@/hooks/table";
import useBranch from "@/hooks/useBranch";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  BranchWarehouseParams,
  BranchWarehouseResponseDto,
} from "@/models/interface/branch";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { SorterProps } from "@/types";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    FormModals,
  },
})
export default class IndexPage extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  modal = {
    show: false,
    branchId: "",
    toggle(): void {
      this.show = !this.show;
    },
  };

  inputSearch: string = (this.$route.query?.q || "").toString();

  loading = false;

  dataBranch: Pagination<BranchWarehouseResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    sorts: "",
    page: +this.$route.query?.p || DEFAULT_PAGE,
    limit: +this.$route.query?.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  columns = [
    {
      title: this.$t("lbl_code"),
      dataIndex: "code",
      key: "code",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_address"),
      dataIndex: "address",
      key: "address",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "edit" },
    },
  ];

  mounted(): void {
    const params = this.buildQuery();
    this.fetchData(params);
  }

  fetchData(params?: RequestQueryParamsModel): void {
    const { findAll } = useBranch();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.dataBranch = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleEdit({ id }: BranchWarehouseResponseDto): void {
    this.modal.branchId = id;
    this.modal.toggle();
  }

  updateUrlSearch(): void {
    const query = {
      p: this.pagination.page.toString(),
      l: this.pagination.limit.toString(),
      q: this.inputSearch,
    };

    this.$router.replace({
      query,
      name: "logistic.branch",
    });
  }

  handleSearch(): void {
    this.pagination.page = DEFAULT_PAGE;
    const params = this.buildQuery();
    this.fetchData(params);
    this.updateUrlSearch();
  }

  buildQuery(): RequestQueryParamsModel {
    const builder = new SearchBuilder();
    const params = new BranchWarehouseParams();
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.sorts = this.pagination.sorts;
    params.byUser = false;

    if (this.inputSearch) {
      params.search = builder
        .push(["name", this.inputSearch], { like: "both" })
        .or()
        .push(["code", this.inputSearch], { like: "both" })
        .or()
        .push(["address", this.inputSearch], { like: "both" })
        .build();
    }

    return params;
  }

  onTableChange(pagination: APagination, _, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;
    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pageSize;

    if (order && order === "ascend") {
      this.pagination.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      this.pagination.sorts = `${columnKey}:desc`;
    }

    const params = this.buildQuery();

    this.fetchData(params);
    this.updateUrlSearch();
  }

  handleReset(): void {
    this.pagination.reset();
    this.inputSearch = "";

    const params = this.buildQuery();
    this.fetchData(params);
    this.updateUrlSearch();
  }

  handleSuccess(): void {
    this.modal.toggle();
    this.modal.branchId = "";
    this.handleReset();
  }

  onCloseModal(): void {
    this.modal.toggle();
    this.modal.branchId = "";
  }
}
